// Main pages for the main nav.
import * as React from 'react';
import { Link, useLocation } from "react-router-dom";
import { userIsLoggedIn } from '../../modules/noomero-auth';

function NavigationMainPages() {

    const location = useLocation();
    return (
        <ul>{false !== userIsLoggedIn() ? (<>if logged in, show some options</>) : location.pathname !== '/explore' ? (<li><Link to="/explore">Explore</Link></li>) : null}
        </ul>
    );

}

export default NavigationMainPages;
