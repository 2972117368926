// Generic button component.

function Button(props) {

    /**
     * Handle actioning any button clicks passed to the component.
     * @param {*} event Click event.
     */
    function onButtonClick(event) {
        if (undefined !== props.onClick) {
            props.onClick();
        }
        // console.log("The button was clicked");
    }

    // @todo Map button variant to specific class.
    const buttonSizes = ['small', 'medium', 'large'];
    const buttonWidths = ['auto-width', 'full-width'];
    const buttonVariants = ['solid', 'outlined'];
    const buttonTones = ['light', 'dark'];
    // Set the button size.
    const buttonSize = buttonSizes.includes(props.buttonSize) && undefined !== props.buttonSize ? props.buttonSize : 'medium';
    const buttonWidth = buttonWidths.includes(props.buttonWidth) && undefined !== props.buttonWidth ? props.buttonWidth : 'auto-width';
    const buttonVariant = buttonVariants.includes(props.buttonVariant) && undefined !== props.buttonVariant ? props.buttonVariant : 'outlined';
    const buttonTone = buttonTones.includes(props.buttonTone) && undefined !== props.buttonTone ? props.buttonTone : 'light';
    return (
        <button
            className={`button ${buttonSize} ${buttonWidth} ${buttonVariant} ${buttonTone}`}
            onClick={onButtonClick}
            type={props.type || "button"}
        >
            <span className="label">{props.children}</ span>
        </ button>);

}

export default Button;
