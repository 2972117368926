// Main pages for the main nav.
import * as React from 'react';
import { Link, useLocation } from "react-router-dom";
import { userIsLoggedIn } from '../../modules/noomero-auth';

function NavigationUserSettings() {

    const location = useLocation();

    return (
        <div>
            {false !== userIsLoggedIn() ? (<div>Logged in, show user settings</div>) : location.pathname !== '/join' ? (<Link to="/join">Get started</Link>) : null}
        </div>
    );

}

export default NavigationUserSettings;
