// Page for signing up.
import React from "react";

class PageJoin extends React.Component {
    render() {
        return (
            <><h1>Get Started</h1><p>Show login options.</p></>
        )
    }
}

export default PageJoin;