// <a href="https://www.flaticon.com/free-icons/letter-n" title="letter n icons">Letter n icons created by Omen Media - Flaticon</a>
import { Link } from "react-router-dom";

function Footer() {
    return (<div id="footer">
        <div>
            <div className="big-links">
                <Link to="/about">About</Link> <a className="social insta" href="https://www.instagram.com/symbolsequence/"><span>Instagram</span></a>
            </div>
            <div className="small-links">
                <a href="https://www.flaticon.com/free-icons/letter-n" title="letter n icons">Letter n icons created by Omen Media - Flaticon</a>
                <a href="https://www.flaticon.com/free-icons/instagram" title="instagram icons">Instagram icons created by Freepik - Flaticon</a>
            </div>
        </div>
    </div>)
}

export default Footer;
